<template>
  <div>
    <b-card>
      <div>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h3>
              <i class="fas fa-users-medical" /> ลงทะเบียนลูกค้า
            </h3>
          </b-col>

          <!-- Search -->
          <b-col md="3" />
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            md="1"
            class="p-0"
            style="margin-top: 20px"
          />
        </b-row>
      </div>
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col md="6">

            <b-form-group
              label="ชื่อ"
              label-for="customDelimiter"
            >
              <validation-provider
                #default="{ errors }"
                name="ชื่อ"
                rules="required"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder=""
                />
                <small class="text-danger">{{
                  errors[0] ? `กรุณากรอกชื่อ` : ''
                }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <b-col md="6">

            <b-form-group
              label="นามสกุล"
              label-for="customDelimiter"
            >
              <validation-provider
                #default="{ errors }"
                name="นามสกุล"
                rules="required"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="surname"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder=""
                />
                <small class="text-danger">{{
                  errors[0] ? `กรุณากรอกนามสกุล` : ''
                }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <b-col md="6">
            <b-form-group
              label="เบอร์โทรศัพท์"
              label-for="customDelimiter"
            >
              <validation-provider
                #default="{ errors }"
                name="เบอร์โทรศัพท์"
                rules="required"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="tel"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  placeholder=""
                />
                <small class="text-danger">{{
                  errors[0] ? `กรุณากรอกเบอร์โทรศัพท์` : ''
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="รหัสผ่าน"
              label-for="customDelimiter"
            >
              <validation-provider
                #default="{ errors }"
                name="รหัสผ่าน"
                rules="required"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder=""
                />
                <small class="text-danger">{{
                  errors[0] ? `กรุณากรอกรหัสผ่าน` : ''
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="ธนาคาร"
              label-for="customDelimiter"
            >
              <v-select
                v-model="bank"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="banklist"
                :reduce="name => name.bankcode"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="เลขที่บัญชี"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="acc_no"
                type="number"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="เบอร์ Truemoney Wallet"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="trueaccno"
                type="number"
                placeholder=""
              />
            </b-form-group>
          </b-col>

          <!-- <b-col md="12">
            <b-form-group
              v-if="UserData.agent_id === 1"
              label="การโอนเงินเข้าครั้งแรก"
              label-for="customDelimiter"
            >
              <b-form-select
                v-model="admintransfer"
                :options="optionsDep"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="6">
            <b-form-group
              label="Line ID"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="line_id"
                type="text"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group
              label="เลือกผู้แนะนำ"
              label-for="customDelimiter"
            >
              <v-select
                v-model="invited_by"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="names"
                :options="optionsMember"
                :reduce="names => names.invite_code"
              />
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="12">
            <b-form-group
              label="สถานะ VIP"
              label-for="customDelimiter"
            >
              <b-form-checkbox
                checked="true"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
            <hr>
          </b-col> -->

          <b-col
            md="12"
            class="mt-1"
          >
            <hr>
            <b-form-group>
              <b-button
                block
                variant="gradient-dark"
                @click.prevent="validationForm"
              >
                <i class="fad fa-cloud-download" />
                บันทึกข้อมูล
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  // BInputGroupAppend,
  BRow,
  BCol,
  // BFormCheckbox,
  // BInputGroupPrepend,
  // BModal,
  BCard,
  // BTable,
  // BAvatar,
  BFormGroup,
  // BFormSelect,
  // BPagination,
  // BInputGroup,
  BFormInput,
  BButton,
  // BCardBody,
  VBToggle,
  // BOverlay,
  // BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
// import { kFormatter } from '@core/utils/filter'
// import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'
// import VueApexCharts from 'vue-apexcharts'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    // BFormCheckbox,
    // BInputGroupAppend,
    BRow,
    BCol,
    // BInputGroupPrepend,
    // BModal,
    BCard,
    // BTable,
    // BAvatar,
    BFormGroup,
    // BFormSelect,
    // BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    // BCardBody,
    // StatisticCardWithAreaChart,
    // // VueApexCharts,
    // BOverlay,
    // BIconController,
    vSelect,
    // flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      zean: false,
      zean_percent: 0,
      line_id: '',
      show: false,
      required,
      banklist: [],
      name: '',
      password: '',
      surname: '',
      bank: '',
      tel: '',
      acc_no: '',
      trueaccno: '',
      status: '',
      invited_by: '',
      admintransfer: null,
      optionsMember: [],
      // options: [
      //   { value: 1, text: 'Active: เข้าเล่นได้ปกติ' },
      //   { value: 2, text: 'Locked: เข้าสู่ล็อบบี้ไม่ได้ เข้าเล่นไม่ได้' },
      //   { value: 0, text: 'Suspended: เข้าสู่ล็อบบี้ได้ แต่เข้าเล่นไม่ได้' },
      // ],
      optionsDep: [
        { value: 0, text: 'โอนผ่านบัญชีลูกค้าปกติ' },
        { value: 1, text: 'call center โอนผ่าน SCB เลขบัญชี ***7913 หรือ ***6330' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
  },
  mounted() {
    this.Get_bank()
  },
  methods: {
    Get_bank() {
      this.$http
        .get('https://uatapi.sabaideelotto.com/api/zean/bank/list')
        .then(response => {
          this.banklist = response.data
        })
        .catch(error => console.log(error))
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.zean === true) {
            this.zean = 1
          } else {
            this.zean = 0
          }
          const params = {
            name: this.name,
            password: this.password,
            surname: this.surname,
            bank: this.bank,
            tel: this.tel,
            acc_no: this.acc_no,
            trueaccno: this.trueaccno,
            status: this.status,
            zean: this.zean,
            zean_percent: this.zean_percent,
            line_id: this.line_id,
            invited_by: this.UserData.ref,
            usertype: 2,
          }
          this.$http
            .post(
              'https://uatapi.sabaideelotto.com/api/zean/users/store',
              params,
            )
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.show = false
              this.Success('เพิ่มลูกค้าเรียบร้อย')
              this.ClearData()
              // this.$router.push({ name: 'member' })
            })
            .catch(error => {
              console.log(error)
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    ClearData() {
      this.name = ''
      this.password = ''
      this.surname = ''
      this.bank = ''
      this.tel = ''
      this.acc_no = ''
      this.trueaccno = ''
      this.status = ''
      this.zean = false
      this.zean_percent = 0
      this.line_id = ''
      this.invited_by = ''
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #000">Error!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style>
  .card-warning {
    background-color: #f4bb00;
    /* border-color: #f8f9fa; */
    /* padding: 10px; */
  }
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form-item-section {
  background-color: $product-details-bg;
}
</style>
